// store.ts

import { createStore, combineReducers } from 'redux';
import authReducer from './auth.reducer';
import sidebarReducer from './sidebar.reducer';

// Root state type
export type RootState = ReturnType<typeof rootReducer>;

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
});

// Create the Redux store
const store = createStore(rootReducer);

export default store;
