
import IUser from "../types/user.type";

export const setCurrentUser = (user: IUser | undefined) => {
  return {
    type: 'SET_CURRENT_USER',
    payload: user,
  };
};

interface AuthState {
  currentUser: IUser | undefined;
  // Other auth-related state properties
}

const initialState: AuthState = {
  currentUser: undefined,
  // Initialize other auth-related state properties
};

const authReducer = (state = initialState, action: any): AuthState => {
  // Handle auth-related actions and update the state accordingly
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
