import * as React from 'react';
import { useNavigate } from "react-router-dom";
import AuthService from '../services/auth.service';
import './appbar.component.css';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import authService from '../services/auth.service';
import SideBar from './sidebar.component';

function HeaderBar() {
    let navigate = useNavigate();
    const user = AuthService.getCurrentUser();
    
    const menuRight = React.useRef(null);
    const items2 = [
        {
            label: 'Profile',
            icon: 'pi pi-user',
            command: () => {
                navigate("/profile");
            }
        },
        {
            label: 'Sign out',
            icon: 'pi pi-sign-out',
            command: () => {
                navigate("/logout")
            }
        }
    ];

    return (
        <>
        
        <div className="container-fluid">
            <div className="row">
            <div className="col text-start p-2 ps-3 header-bar">
                    <Button   text   severity="secondary" aria-label="Menu" className=" p-0  d-lg-none" 
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu"
                    >
                        <i className="pi pi-bars" style={{marginTop:'5px', fontSize:'18px'}}></i> 
                    </Button>
                    <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex={-1} id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasMenuLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <SideBar role={authService.checkCompanyRole()}></SideBar>
                        </div>
                    </div>
                </div>
                <div className="col text-end p-2 pe-4 header-bar">
                    <Menu model={items2} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                    <Button  rounded text   severity="secondary" aria-label="User" className="me-1 p-0" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup >
                    <img className="header-profile-img" src={user.imagePath}  alt=""></img>
                    <span className='header-profile-name d-none d-lg-block'> {user.firstName} {user.lastName} </span>
                    <span className='ms-1'><svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 6L0.803849 0.749999L11.1962 0.75L6 6Z" fill="#333333"/>
                    </svg></span>
                    </Button>
                </div>
            </div>
        </div>
       
        </>
    );
}
export default HeaderBar;