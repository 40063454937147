
import { useNavigate, NavLink } from "react-router-dom";

import AuthService from '../services/auth.service';
import './sidebar.component.css';
import authService from '../services/auth.service';
import { useEffect, useState } from "react";
import { getSubdomain } from "../services/getSubdomain";
type SidebarProps = {
    role: string;
  };
export default function SideBar({role}: SidebarProps) {
    let [user, setUser]:any = useState<any | null>(null);
    let [userRole, setUserRole]:any = useState<any | null>(null);
    let [subdomain, setSubdomain]:any = useState<any | null>(null);
    
    
    useEffect(() => {
        const fetchData = () => {
        try {
            setUser(AuthService.getCurrentUser());
            setUserRole(authService.checkCompanyRole());
            setSubdomain(getSubdomain());
          }catch (error) {
            console.log('Error fetching data:', error);
          };
      
        };
        fetchData();
    }, [userRole]);
    
    return (
        <>
        {user ? (
         <div 
             
            id="apiSidebar" className="default-sidebar main-sidebar"
            >
            <div className="title-header">
                <div style={{ display: "inline-block" }}> <img src={ user.company.logoImagePath} style={{maxWidth:'220px'}}/></div>
            </div>
            <div className="sub-title">
               
            </div>
            <div className="sidebar-profile">
                <img className="avatar-img" src={user.imagePath} alt=""></img>
               
               <div className='avatar-details'>
                    <div className='avatar-name'>{user.firstName} {user.lastName}</div>
                    <div className='avatar-email'>{user.email}</div>
               </div>
            </div>
            <ul className='sidebar-menu'>
                <li> 
                    <NavLink to="/"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( ' side-nav-item' )
                        }>
                        <span className="ip-icon ipicon-dashboard"></span> Dashboard 
                    </NavLink>
                </li> 
                <li><NavLink to="/workshops"
                    className={ 
                        ( {isActive} ) => ( isActive 
                            ? 'active' 
                            : ''
                        ).concat( '' )
                    }>
                        <span className="ip-icon ipicon-workshops"></span> Requests 
                </NavLink>
                </li>
                { [authService.ROLE_MANAGER, authService.ROLE_ADMIN].includes(authService.checkCompanyRole() ) &&
                  <li>
                    <NavLink to="/user"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( '' )
                        }>
                        <span className="ip-icon ipicon-users"></span> Users
                    </NavLink>
                </li>
                }
                { [authService.ROLE_MANAGER, authService.ROLE_ADMIN].includes(authService.checkCompanyRole() ) &&
                    <li>
                      <NavLink to="/coach"
                          className={ 
                              ( {isActive} ) => ( isActive 
                                  ? 'active' 
                                  : ''
                              ).concat( '' )
                          }>
                          <span className="ip-icon ipicon-coaches"></span> Coaches
                      </NavLink>
                  </li>
                  }
                {/* 
                <li>
                    <NavLink to="/messaging"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( '' )
                        }>
                        <span className="ip-icon ipicon-chat"></span> Inbox
                    </NavLink>
                </li>
                */}
                {subdomain != 'eca' &&
                <li>
                    <NavLink to="/calendar"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( '' )
                        }>
                        <span className="ip-icon ipicon-schedule"></span> Calendar
                    </NavLink>
                </li>
                }
                {[authService.ROLE_MANAGER, authService.ROLE_ADMIN].includes(authService.checkCompanyRole() ) &&
                <li>
                    <NavLink to="/stats"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( '' )
                        }>
                        <span className="ip-icon ipicon-timeline"></span> Reports
                    </NavLink>
                </li>
                }
                { [authService.ROLE_ADMIN].includes(authService.checkCompanyRole() ) &&
                <li style={{position:"absolute", bottom: 0}}>
                    <NavLink to="/preferences"
                        className={ 
                            ( {isActive} ) => ( isActive 
                                ? 'active' 
                                : ''
                            ).concat( '' )
                        }>
                        <span className="ip-icon ipicon-settings"></span> Preferences
                    </NavLink>
                </li>
                }
               
            </ul>
               
        </div>
        ):(
            <div>Loading Sidebar...</div>
        )}
        </>
    );
    

}
