// Action types
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Action creators
export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

interface SidebarState {
  showSidebar: boolean;
}

const initialState: SidebarState = {
  showSidebar: false,
};

const sidebarReducer = (state = initialState, action: any): SidebarState => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    default:
      return state;
  }
};

export default sidebarReducer;