import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useNavigate, NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";
import authService from "../services/auth.service";

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from "../services/store";
import { setCurrentUser } from "../services/auth.reducer";

interface Props extends PropsFromRedux {};

const mapStateToProps = (state: RootState) => {
  return {
    // Map any necessary state from the Redux store
  };
};

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  redirect: string | null,
  reload: boolean | null,
  email: string,
  loading: boolean,
  message: string,
  messagesuccess: string
};

class PasswordForgot extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      redirect: null,
      reload: null,
      email: "",
      loading: false,
      message: "",
      messagesuccess: "",
    };
  }

  async componentDidMount() {

    const currentUser = await AuthService.getCurrentUser();
    if (currentUser && !this.state.loading) {
      this.setState({ redirect: "/" });
    };
  }

  componentWillUnmount() {
    //window.location.reload();
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string().required("This field is required!"),
    });
  }

  async handleLogin(formValue: { email: string; }) {
    const { email } = formValue;

    this.setState({
      message: "",
      loading: true
    });

    await AuthService.forgotPassword(email)
    .then(response => {
      this.setState({
        loading: false,
        messagesuccess: "Please check your inbox!. Password instructions sent if email was found."
      });
    },
      error => {
        console.log(error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: error.response.data.error
        });
      }
    );

  }

  render() {

    if (this.state.reload && !this.state.loading) {
      //window.location.reload();
    }
    if (this.state.redirect && !this.state.loading) {
      return <Navigate to={this.state.redirect} />
    }

    const { loading, message, messagesuccess } = this.state;

    const initialValues = {
      email: "",
    };

    return (
      <div className="container">
        <div className="row mt-4"  >
          <div className="col-md-6 text-center">
            <div style={{ display: "inline-block" }}> <img src={process.env.PUBLIC_URL + '/img/Innophant_Logo_large.svg'} /></div>
          </div>
          <div className="col-md-6  text-center">
            Already have an account?
            <NavLink to="/login" className="btn btn-light ms-3"> Login </NavLink>
                    
          </div>
        </div>
        <div className="row " style={{ marginTop: '3rem'}}  >
          <div className="col-lg-6 text-center d-none d-lg-block">
            <div style={{ fontSize: '67px'}} >
              <img src={process.env.PUBLIC_URL + '/img/logo-gray.svg'} />
              innophant
            </div>

            <div style={{ fontSize: '28px'}} >
              Connecting your talent to boost business innovation.
            </div>

            <div style={{ marginTop: '3rem'}} >
              <img src={process.env.PUBLIC_URL + '/img/login.svg'} />
              
            </div>
          </div>
          <div className="col-lg-6  text-left ps-lg-5 pe-lg-5">
            <div className="mt-5 mb-5" style={{ fontSize: '28px'}}>
              <strong>innophant</strong> - the Creative Workshop Marketplace
            </div>
            <div className="mt-5 mb-5">
            Please enter your Email to reset your password.
            </div>
            <div className="mt-5 mb-5">
            <Formik
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleLogin}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <Field name="email" type="text" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group mt-5 d-grid gap-2">
                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Reset Password</span>
                  </button>
                 
                </div>

                {message && (
                  <div className="form-group mt-3">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                {messagesuccess && (
                  <div className="form-group mt-3">
                    <div className="alert alert-success" role="alert">
                      {messagesuccess}
                    </div>
                  </div>
                )}
              </Form>
            </Formik> 
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}export default connector(PasswordForgot);