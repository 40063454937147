import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useNavigate, NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";
import authService from "../services/auth.service";
import { RootState } from "../services/store";
import { setCurrentUser } from "../services/auth.reducer";
import { ConnectedProps, connect } from "react-redux";

interface Props extends PropsFromRedux {};

const mapStateToProps = (state: RootState) => {
  return {
    // Map any necessary state from the Redux store
  };
};

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = { 
  redirect: string | null,
  reload: boolean | null,
};


class Logout extends Component<Props, State> {
  

  async componentDidMount() {
    AuthService.logout();
    this.props.setCurrentUser(null);
    this.setState({ redirect: "/login" });
  }

  componentWillUnmount() {
    //window.location.reload();
  }

  

  render() {

    if(!this.state)
      return <></>;
    if (this.state.reload ) {
      //window.location.reload();
    }
    if (this.state.redirect ) {
      return <Navigate to={this.state.redirect} />
    }

    return (<></>
      
    );
  }
}
export default connector(Logout);