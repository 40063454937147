import React, {lazy, Suspense} from 'react';
import { Component } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import IUser from './types/user.type';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './services/store';
import { toggleSidebar } from './services/sidebar.reducer';
import { setCurrentUser } from './services/auth.reducer';
import jwt_decode from 'jwt-decode'

import authService from './services/auth.service';

import SideBar from './components/sidebar.component';
import HeaderBar from './components/appbar.component';
import LoadingContent from './components/loading.content';

import Login from './pages/login.page';
import Logout from './pages/logout.page';
import Register from './pages/register.page';
import PasswordForgot from './pages/password.forgot.page';
import PasswordReset from './pages/password.reset.page';

import './App.css';
// Registering Syncfusion license key
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTX9Ud01mWX5Wd3NURQ==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckRkWHdbcHVdQWhYUg==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkFiXn5WdXRUTmhcUkc=;MTc1NDU2OUAzMjMwMmUzNDJlMzBFek9ycVdxVnhvWVJXbjhsVUFSZVdMb3hOSmRxNHU2M240TXJneld3L1NBPQ==;MTc1NDU3MEAzMjMwMmUzNDJlMzBFT3dyczBpZkY2VkpaTERkMzdPTis1SW5lNm1TRzZVZmNXZWxNU2RzQ2VjPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVnWHhfeHFVR2hUVkR1;MTc1NDU3MkAzMjMwMmUzNDJlMzBPWVU1TTNGNVJWdmxsaFkwTVlPQ2xhS2QwSFl1RU1yZUE2TmhoVFRvY0lzPQ==;MTc1NDU3M0AzMjMwMmUzNDJlMzBGTUZVUmliZEpwRmJDRUJFRTNqRUl0QUJBbzZ3QUp6Qy9XMHJjUGo1aXdzPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkFiXn5WdXRUTmlYV0c=;MTc1NDU3NUAzMjMwMmUzNDJlMzBTQ2xBdGZocUhhWTdudHA2VVpYNjFrQ2Zna2dCVEY2RkZBem5YTGtSQWlNPQ==;MTc1NDU3NkAzMjMwMmUzNDJlMzBVNmtCUmd5T3N0ZlIwMThvTEdZMTdWMG5BRGJpZ0lFb2xnSTFVZnErRVdRPQ==;MTc1NDU3N0AzMjMwMmUzNDJlMzBPWVU1TTNGNVJWdmxsaFkwTVlPQ2xhS2QwSFl1RU1yZUE2TmhoVFRvY0lzPQ==');


type State = {
  showCoachBoard: boolean,
  showAdminBoard: boolean,
  currentUser: IUser | undefined,
  redirect: string | null,
}
interface AppProps extends PropsFromRedux {
  currentUser: IUser | undefined;
}
const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.auth.currentUser,
    showSidebar: state.sidebar.showSidebar,
  };
};

const mapDispatchToProps = {
  toggleSidebar: toggleSidebar,
  setCurrentUser: setCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends Component<AppProps, State> {
  
  componentDidMount() {
    const user = authService.getCurrentUser();
    if (user) {
      this.props.setCurrentUser(user);
      this.setState({
        currentUser: user
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const RequireAuth = ({ children }) => {
      //return children;
      const userStr = localStorage.getItem("user");
      if(userStr){
        try {
          let user = JSON.parse(userStr);
          let userObject : any = jwt_decode(user.accessToken);
          var dateNow = new Date();
          if(userObject.exp * 1000 > dateNow.getTime())
            return children
        } catch (error) {
          return  <Navigate to="/logout" />;
        }
      }
      return  <Navigate to="/logout" />;
    };


    const Profile  = lazy(() => import('./pages/profile.page'));
    const Dashboard  = lazy(() => import('./pages/dashboard.page'));
    const WorkshopList = lazy(() => import('./pages/workshop.list.page'))
    const WorkshopDetail  = lazy(() => import('./pages/workshop.detail.page'));
    const WorkshopRemove  = lazy(() => import('./pages/workshop.remove.page'));
    const Calendar  = lazy(() => import('./pages/calendar.page'));
    const Report  = lazy(() => import('./pages/report.page'));
    const WorkshopApprove  = lazy(() => import('./pages/workshop.approve.page'));
    const WorkshopCancel  = lazy(() => import('./pages/workshop.cancel.page'));
    const UserList  = lazy(() => import('./pages/user.list.page'));
    const WorkshopInitMessaging  = lazy(() => import('./pages/workshop.init-messaging.page'));
    const Messaging  = lazy(() => import('./pages/messaging.thread.page'));
    const UserDetail  = lazy(() => import('./pages/user.detail.page'));
    const UserEdit  = lazy(() => import('./pages/user.edit.page'));
    const ProfileEdit  = lazy(() => import('./pages/profile.edit.page'));
    const WorkshopCoachSelect  = lazy(() => import('./pages/workshop.coach.select.page'));
    const CoachList  = lazy(() => import('./pages/coach/coach.list.page'));
    const CoachWorkshop  = lazy(() => import('./pages/coach/coach.workshop'));
    const CoachWorkshopAccept  = lazy(() => import('./pages/coach/coach.workshop.accept.page'));
    const CoachWorkshopDeclined  = lazy(() => import('./pages/coach/coach.workshop.declined.page'));
    const CompanyPreferences  = lazy(() => import('./pages/preferences/company.preferences.page'));
    const CompanyWorkshopTypeEdit  = lazy(() => import('./pages/preferences/company.workshop-type.edit.page'));
    const CompanyWorkshopPlatformEdit  = lazy(() => import('./pages/preferences/company.workshop-platform.edit.page'));
    const CompanyWorkshopMethodEdit  = lazy(() => import('./pages/preferences/company.workshop-method.edit.page'));
    const StartupOnboarding  = lazy(() => import('./pages/startup.onboarding.page'));
    const CoachOnboardingStep1  = lazy(() => import('./pages/coach/coach.onboarding.step1.page'));
    const CoachOnboardingStep2  = lazy(() => import('./pages/coach/coach.onboarding.step2.page'));
    const CoachOnboardingStep3  = lazy(() => import('./pages/coach/coach.onboarding.step3.page'));
    const WorkshopRequestStep1  = lazy(() => import('./pages/workshop.request.step1.page'));
    const WorkshopRequestStep2  = lazy(() => import('./pages/workshop.request.step2.page'));
    const UserChangeRole  = lazy(() => import('./pages/user.change.role.page'));
    const WorkshopRequestFadeDownWrap  = lazy(() => import('./pages/workshop.request.fadedownwrap.page'));
    const MentoringRequestFadeDownWrap = lazy(() => import('./pages/mentoring.request.fadedownwrap.page'));
    const UserRemove  = lazy(() => import('./pages/user.remove.page'));
    
    return (
      <div className="control-section">
        <div id="wrapper">
          {currentUser && (
            <aside className='d-none d-lg-block'>
              <SideBar role={authService.checkCompanyRole()}></SideBar>
            </aside>
            )}
          <div className="content">
            {currentUser &&(
              <HeaderBar></HeaderBar>
            )}
            <div className="main">
              <Suspense fallback={<LoadingContent title=''></LoadingContent>}>
                <Routes>
                  <Route path="/" element={ <RequireAuth><Dashboard /></RequireAuth>} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/password-forgot" element={<PasswordForgot />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/coach_workshop" element={<CoachWorkshop />} />
                  <Route path="/calendar" element={<RequireAuth><Calendar /></RequireAuth>} />
                  <Route path="/stats" element={<RequireAuth><Report /></RequireAuth>} />
                  <Route path="/messaging" element={<RequireAuth><Messaging /></RequireAuth>} />
                  <Route path="/messaging/:id" element={<RequireAuth><Messaging /></RequireAuth>} />
                  <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
                  <Route path='/profile/edit' element={<RequireAuth><ProfileEdit/></RequireAuth>} />
                  <Route path="/user" element={<RequireAuth><UserList /></RequireAuth>} />
                  <Route path="/user/onboarding" element={<RequireAuth><StartupOnboarding /></RequireAuth>} />
                  <Route path='/user/:id/detail' element={<RequireAuth><UserDetail /></RequireAuth>} />
                  <Route path='/user/:id/edit' element={<RequireAuth><UserEdit/></RequireAuth>} />
                  <Route path='/user/:id/changerole/:role' element={<RequireAuth><UserChangeRole/></RequireAuth>} />
                  <Route path='/user/:id/remove' element={<RequireAuth><UserRemove/></RequireAuth>} />
                  <Route path="/coach" element={<RequireAuth><CoachList /></RequireAuth>} />
                  <Route path="/coach/onboarding/methods" element={<RequireAuth><CoachOnboardingStep1 /></RequireAuth>} />
                  <Route path="/coach/onboarding/experience" element={<RequireAuth><CoachOnboardingStep2 /></RequireAuth>} />
                  <Route path="/coach/onboarding/information" element={<RequireAuth><CoachOnboardingStep3 /></RequireAuth>} />
                  <Route path='/coach/:id/detail' element={<RequireAuth><UserDetail /></RequireAuth>} />
                  <Route path='/coach/:id/edit' element={<RequireAuth><UserEdit/></RequireAuth>} />
                  <Route path="/workshops" element={<RequireAuth><WorkshopList /></RequireAuth>} />
                  <Route path='/workshops/request_workshop' element={<RequireAuth><WorkshopRequestFadeDownWrap /></RequireAuth>} />
                  <Route path='/workshops/request_mentoring' element={<RequireAuth><MentoringRequestFadeDownWrap /></RequireAuth>} />
                  <Route path='/workshops/request' element={<RequireAuth><WorkshopRequestFadeDownWrap /></RequireAuth>} />
                  <Route path='/workshops/:id/request' element={<RequireAuth><WorkshopRequestStep1 /></RequireAuth>} />
                  <Route path='/workshops/:id/create' element={<RequireAuth><WorkshopRequestStep2 /></RequireAuth>} />
                  <Route path='/workshops/:id/detail' element={<RequireAuth><WorkshopDetail /></RequireAuth>} />
                  <Route path='/workshops/:id/remove' element={<RequireAuth><WorkshopRemove /></RequireAuth>} />
                  <Route path='/workshops/:id/approve' element={<RequireAuth><WorkshopApprove /></RequireAuth>} />
                  <Route path='/workshops/:id/cancel' element={<RequireAuth><WorkshopCancel /></RequireAuth>} />
                  <Route path='/workshops/:id/coach/:coachId/select' element={<RequireAuth><WorkshopCoachSelect /></RequireAuth>} />
                  <Route path='/workshops/:id/coach_accept' element={<RequireAuth><CoachWorkshopAccept /></RequireAuth>} />
                  <Route path='/workshops/:id/coach_declined' element={<RequireAuth><CoachWorkshopDeclined /></RequireAuth>} />
                  <Route path='/workshops/:id/init-messaging' element={<RequireAuth><WorkshopInitMessaging /></RequireAuth>} />
                  <Route path='/preferences' element={<RequireAuth><CompanyPreferences /></RequireAuth>} />
                  <Route path='/preferences/workshop-types/:id/edit' element={<RequireAuth><CompanyWorkshopTypeEdit /></RequireAuth>} />
                  <Route path='/preferences/workshop-types/add' element={<RequireAuth><CompanyWorkshopTypeEdit /></RequireAuth>} />
                  <Route path='/preferences/workshop-platforms/:id/edit' element={<RequireAuth><CompanyWorkshopPlatformEdit /></RequireAuth>} />
                  <Route path='/preferences/workshop-platforms/add' element={<RequireAuth><CompanyWorkshopPlatformEdit /></RequireAuth>} />
                  <Route path='/preferences/workshop-methods/:id/edit' element={<RequireAuth><CompanyWorkshopMethodEdit /></RequireAuth>} />
                  <Route path='/preferences/workshop-methods/add' element={<RequireAuth><CompanyWorkshopMethodEdit /></RequireAuth>} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(App);
