import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useNavigate, NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";
import authService from "../services/auth.service";

type Props = {};

type State = {
  redirect: string | null,
  reload: boolean | null,
  email: string,
  token: string,
  password: string,
  loading: boolean,
  message: string,
  preMessage: string,
  messagesuccess: string
};

export default class PasswordReset extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      redirect: null,
      reload: null,
      email: "",
      token: "",
      password: "",
      loading: false,
      message: "",
      preMessage: "",
      messagesuccess: ""
    };
  }

  async componentDidMount() {

    const currentUser = await AuthService.getCurrentUser();
    if (currentUser) {
      this.setState({ redirect: "/" });
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get("email")
    const token = queryParameters.get("token")

    this.setState({ email: email, token: token });
    await authService.getPasswordReset(email, token).then(
      response => {
      },
      error => {

    this.setState({ preMessage: "Hi, looks like we could not find your email or reset key. Please get in touch with your innovation manager!" });
      })
    

  }

  componentWillUnmount() {
    //window.location.reload();
  }

  validationSchema() {
    return Yup.object().shape({
      password: Yup.string().required("This field is required!").min(8, 'Your password is too short.'),
      password_repeat: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
  }

  async handleLogin(formValue: { password_repeat: string; password: string, firstName: string, lastName: string }) {
    const { password, password_repeat  } = formValue;

    this.setState({
      message: "",
      loading: true
    });

    await AuthService.resetPassword(this.state.email, this.state.token, password).then(
      () => {
        this.setState({
          loading: false,
          messagesuccess: "Password changed succesfully!"
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );

  }

  render() {
    if (this.state.reload) {
      window.location.reload();
    }
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { loading, message, messagesuccess } = this.state;

    const initialValues = {
      password: "",
      password_repeat: "",
      firstName: "",
      lastName: "",
    };

    return (
      <div className="container">
        <div className="row mt-4"  >
          <div className="col-md-6 text-center">
            <div style={{ display: "inline-block" }}> <img src={process.env.PUBLIC_URL + '/img/Innophant_Logo_large.svg'} /></div>
          </div>
          <div className="col-md-6  text-center">
            Already have an account?
            <NavLink to="/login" className="btn btn-light ms-3"> Login </NavLink>
                    
          </div>
        </div>
        <div className="row " style={{ marginTop: '3rem'}}  >
          <div className="col-lg-6 text-center d-none d-lg-block">
            <div style={{ fontSize: '67px'}} >
              <img src={process.env.PUBLIC_URL + '/img/logo-gray.svg'} />
              innophant
            </div>

            <div style={{ fontSize: '28px'}} >
              Connecting your talent to boost business innovation.
            </div>

            <div style={{ marginTop: '3rem'}} >
              <img src={process.env.PUBLIC_URL + '/img/login.svg'} />
              
            </div>
          </div>
          <div className="col-lg-6  text-left ps-lg-5 pe-lg-5">
            <div className="mt-5 mb-5" style={{ fontSize: '28px'}}>
              <strong>Join innophant</strong> - the Creative Workshop Marketplace
            </div>
            <div className="mt-5 mb-5">
              &nbsp;
            </div>
            <div className="mt-5 mb-5">
            {this.state.preMessage.length > 0 ? (
              <div className="alert alert-info">{this.state.preMessage}</div>
            )
            :
            (
              <Formik
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleLogin}
            >
              <Form>
                

                <div className="form-group mt-3">
                  <label htmlFor="password">Password</label>
                  <Field name="password" type="password" className="form-control" />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="password_repeat">Repeat Password</label>
                  <Field name="password_repeat" type="password" className="form-control" />
                  <ErrorMessage
                    name="password_repeat"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                
                <div className="form-group d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Set new password</span>
                  </button>
                 
                </div>

                {message && (
                  <div className="form-group mt-3">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                {messagesuccess && (
                  <div className="form-group mt-3">
                    <div className="alert alert-success" role="alert">
                      {messagesuccess}
                    </div>
                  </div>
                )}
              </Form>
            </Formik> 
            )
            }
            
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}