// src/utils/getSubdomain.ts
export const getSubdomain = (): string | null => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
  
    if (parts.length > 1) {
      // Assuming the format is subdomain.domain.tld
      return parts[0];
    }
  
    return null;
  };
  