import React from 'react';

import './loading.content.css';

type LoadingContentProps = {
  title: string 
};

const LoadingContent: React.FC<LoadingContentProps> = ({ title}) => {
  return  <div className="container-fluid p-3">
      <div className="row">
        <div className="col text-center p-5 mt-5 mb-5">
          <div className='elementToFadeInAndOut'>
            <img className='loading-img' src={process.env.PUBLIC_URL + '/img/logo-mono.svg'}></img>
          </div>
          <div className='loading-title'>
            Loading {title} ...
          </div>
          <div className='loading-subtitle'>
            We're almost there
          </div>
        </div>
      </div>
    </div>;
};

export default LoadingContent;
