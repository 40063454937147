import axios from "axios";

import { API_URL } from "./api-url";
import jwt_decode from 'jwt-decode'
import authHeader from "./auth-header";

const AUTH_API_URL = API_URL + 'auth';

class AuthService {

  public ROLE_ADMIN : string = "admin";
  public ROLE_MANAGER : string = "manager";
  public ROLE_COACH : string = "coach";
  public ROLE_MEMBER : string = "member";
  
  checkCompanyRole() : string{
    let user = this.getCurrentUser()
    if(user)
    {
      return user.company.role;
    }
      return "";
  }

  login(email: string, password: string) {

    let resp =  axios
      .post(AUTH_API_URL + "/login", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      });
      return resp;
  }

  forgotPassword(email: string) {

    return axios
      .post(AUTH_API_URL + "/password-forgot", {
        email
      })
      ;
  }

  getPasswordReset(email: string,  token: string) {
    return axios.get(AUTH_API_URL + "/password-reset", {
      params: { 
        email:email, 
        token:token 
      }
    });
  }

  resetPassword(email: string, token: string, password: string) {

    return axios
      .post(AUTH_API_URL + "/password-reset", {
        email: email,
        token:token ,
        password:password 
      })
      ;
  }

  logout() {
    localStorage.clear();
  }

  getInvitation(email: string,  token: string) {
    {
      return axios.get(AUTH_API_URL + "/invite", {
        params: { 
          email:email, 
          token:token 
        }
      });
    }
  }

  register(email: string, password: string, firstName: string, lastName: string, companyName:string) {
    return axios.post(AUTH_API_URL + "/register", {
      email,
      password,
      firstName,
      lastName,
      companyName
    }, {  params: { companyId: localStorage.getItem("companyId") } })
    .then(response => {
      console.log(response);
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });;
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    let returnUser : any | null = null;
    
    if (userStr)
    {
      let user = JSON.parse(userStr);
      if(user && user.accessToken){
        returnUser = jwt_decode(user.accessToken);
        return returnUser.user;
      }
    }
    return null;
  }
}

export default new AuthService();
